<template>
<div class="bg-default">
    <header class="header">
        <div class="header-left">
            <a href="#">
                <img class="header-settings-icon" src="img/settings-icon.png" alt="Settings">
            </a>
        </div>
        <div class="header-center justify-end">
            <div class="header-card">
                <div class="header-card-text">
                    hátralévő<br>
                    idő
                </div>
                <market-timer 
                        @roundEnded="roundEnd()"
            :startTime="option"></market-timer>
            </div>
        </div>
        <div class="header-right">
            <a @click="exit()">
                <img class="header-settings-icon" src="img/exit-icon.png" alt="Exit">
            </a>
        </div>
    </header>
    <main class="main">
        <div class="container">
            <div class="card card-800 card-centerred">
                <div class="card-subtitle mt-32 mb-48">
                    Mennyire ismered a tőzsde világát,<br>
                    vagy olvasol gazdasági híreket?
                </div>
                <div class="card-buttons">
                    <a @click="chooseDifficulty(1)" :class="['button', 'button-blue', 'button-fullwidth', difficulty === 1 ? 'button-shadow-small' : 'button-outline']">Nem tudom, mi az a tőzsde.</a>
                    <a @click="chooseDifficulty(2)" :class="['button', 'button-blue', 'button-fullwidth', difficulty === 2 ? 'button-shadow-small' :'button-outline']">Tudom, mi az a tőzsde. Nem olvasok gazdasági híreket.</a>
                    <a @click="chooseDifficulty(3)" :class="['button', 'button-blue', 'button-fullwidth', difficulty === 3 ? 'button-shadow-small' : 'button-outline']">Tudom, mi az a tőzsde. Olvasok gazdasági híreket.</a>
                </div>
            </div>
        </div>
    </main>
    <footer class="footer">
        <div class="container">
            <div class="footer-button-only">
                <a @click="goNext()" class="button button-blue">Tovább</a>
            </div>
        </div>
    </footer>
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import MarketTimer from '../components/MarketTimer.vue';
export default {
  components: { MarketTimer },
    computed: {
        ...mapGetters([
            'difficulty',
            // ...
        ]),
    },
    data: function() {
        return {
            option: {
                time: this.$config.settings.difficultyWaitingTime, option: 'round'
            }
        }
    },
    methods: {
        chooseDifficulty(diff) {
            this.$store.commit('setDifficulty', diff);
        },
        goNext() {
            if (!this.difficulty) return;
            this.$router.push('exchange');
        },
        roundEnd() {
            this.goNext();
        }
    }
}
</script>

<style>

</style>
